import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../../app/shared/shared.module';
import { environment } from '../../environments/environment';
import { CustomHttpInterceptor } from './interceptors/http.interceptor';
import { PreloaderComponent } from './preloader/preloader.component';
import { AppInitializationService } from './services/app.initialization.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function get_settings(startUpService: AppInitializationService) {
  return () => startUpService.getSettings();
}

@NgModule({
  declarations: [PreloaderComponent],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.googleTrackingId),
    NgxGoogleAnalyticsRouterModule,
  ],
  exports: [PreloaderComponent, CommonModule, ToastrModule, TranslateModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: get_settings,
    //   deps: [AppInitializationService],
    //   multi: true,
    // },
  ],
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
