import Big from 'big.js';

export class MathUtils {
  static isNaN(operand1: number, operand2: number): boolean {
    return (
      Number.isNaN(parseFloat(operand1 + '')) ||
      Number.isNaN(parseFloat(operand2 + ''))
    );
  }

  private static formatDecimal(value: Big): number {
    Big.RM = 0;
    return Big(value.toFixed(2)).toNumber();
  }

  static trimToTwoDecimals(value: any): any {
    if (Number.isNaN(parseFloat(value + ''))) {
      return value;
    }
    return this.formatDecimal(Big(value));
  }

  static add(operand1: any, operand2: any): any {
    if (this.isNaN(operand1, operand2)) {
      return operand1 + operand2;
    }
    return this.formatDecimal(Big(operand1).plus(operand2));
  }

  static sub(operand1: any, operand2: any): any {
    if (this.isNaN(operand1, operand2)) {
      return operand1 - operand2;
    }
    return this.formatDecimal(Big(operand1).minus(operand2));
  }

  static mul(operand1: any, operand2: any): any {
    if (this.isNaN(operand1, operand2)) {
      return operand1 * operand2;
    }
    return this.formatDecimal(Big(operand1).mul(operand2));
  }

  static div(operand1: any, operand2: any): any {
    if (this.isNaN(operand1, operand2)) {
      return operand1 / operand2;
    }
    return this.formatDecimal(Big(operand1).div(operand2));
  }

  static percentageOf(operand1: any, operand2: any): any {
    if (this.isNaN(operand1, operand2)) {
      return (operand1 * operand2) / 100;
    }
    return this.formatDecimal(Big(operand1).mul(operand2).div(100));
  }

  static calcPercentage(operand1: any, operand2: any): any {
    if (this.isNaN(operand1, operand2)) {
      return (operand1 / operand2) * 100;
    }
    return this.formatDecimal(Big(operand1).div(operand2).mul(100));
  }
}
