import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
  @Output() onClosingModal: EventEmitter<any> = new EventEmitter();
  @ViewChild('paymentSuccessModal') paymentSuccessModal: any;
  modalRef!: BsModalRef;

  constructor(public modalService: BsModalService) {}

  ngOnInit(): void {}

  openModal(): void {
    const config = {
      class: 'modal-dialog-centered',
    };
    this.modalRef = this.modalService.show(this.paymentSuccessModal, config);
    this.modalRef.onHidden?.subscribe(() => {
      this.onClosingModal.emit();
    });
  }

  closeAllModals(): void {
    this.modalRef?.hide();
  }
}
