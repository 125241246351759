export enum MobileAppEvents {
  SessionExpired = 'SessionExpired',
  GoToHomePage = 'GoToHomePage',
  GetUpiIntentDetails = 'GetUpiIntentDetails',
  UpiIntentDetailsResponse = 'UpiIntentDetailsResponse',
  PaymentGatewayOpened = 'PaymentGatewayOpened',
  PaymentGatewayDismissed = 'PaymentGatewayDismissed',
  OpenPaymentGateway = 'OpenPaymentGateway',
  PaymentSuccess = 'PaymentSuccess',
  PaymentFailed = 'PaymentFailed',
  GoBack = 'GoBack',
}
