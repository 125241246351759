import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { Constants } from './configs/app.config';
import { AnalyticsService } from './core/services/analytics.service';
import { AuthService } from './core/services/auth.service';
import { NetworkStatusService } from './core/services/network-status.service';
import { isMobileApp } from './shared/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ohmPayWeb';
  isOnline: boolean = true;
  _onDestroy = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private appConstants: Constants,
    private router: Router,
    private networkStatusService: NetworkStatusService,
    private analyticsService: AnalyticsService
  ) {
    this.translateService.use(this.appConstants.defaultLanguage);
    this.router.events
      .pipe(takeUntil(this._onDestroy))
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.analyticsService.trackPageView(event.urlAfterRedirects);
      });

    if (isMobileApp()) {
      this.networkStatusService.isOnline
        .pipe(takeUntil(this._onDestroy))
        .subscribe((status) => {
          this.isOnline = status;
        });
    }
  }

  refresh(): void {
    window.location.reload();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
