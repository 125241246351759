<div class="dash-box">
  <img [src]="image">
  <div *ngIf="message" class="message" [innerHtml]="message | translate" [style.font-size.px]="messageFontSize ? messageFontSize : 24">
  </div>
  <div *ngIf="primaryButtonTitle" class="buttons-container" [matTooltip]="primaryButtonTooltip"
    [matTooltipDisabled]="primaryButtonTooltip ? false : true">
    <button mat-raised-button color="primary" class="action-button" (click)="_onPrimaryButtonClick()"
      [disabled]="isPrimaryButtonDisabled">
      {{this.primaryButtonTitle | translate}}
    </button>
  </div>
</div>
