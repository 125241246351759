<ng-template #paymentSuccessModal>
  <div class="modal-success">
    <div class="modal-header">
      <div class="icon-box">
        <mat-icon class="icon">check</mat-icon>
      </div>
      <h4 class="modal-title w-100">{{'GOALS.AWESOME' | translate}}!</h4>
    </div>
    <div class="modal-body">
      <p class="text-center">{{'GOALS.PAYMENT_SUCCESSFUL' | translate}}</p>
    </div>
  </div>
  <button type="button" class="btn btn-success btn-block" (click)="closeAllModals()">{{'BUTTONS.OK' | translate}}</button>
</ng-template>
