import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  sendOtp(data: any): Observable<any> {
    const url = 'login';
    return this.http.post(url, data);
  }

  validateOtp(data: any): Observable<any> {
    const url = 'otp-validation';
    return this.http.post(url, data);
  }

  refreshToken(data: any): Observable<any> {
    const url = 'refresh-token';
    return this.http.post(url, data, {
      params: {
        _config: '{"noNotifyError": "true"}',
      },
    });
  }
}
