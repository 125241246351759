import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Constants {
  EncryptKey: string;
  SecretKeyForEncryptionWithIv: string;
  IV: string;
  APIBasePath!: string;
  defaultLanguageId: number;
  notificationTimeout: number;
  commonAPIErrorMessage: string;
  validationMessages: any;
  APIRequestTimeout: number;
  defaultLanguage: string;
  defaultLanguageObj: any;
  countryCode: any;
  numbersOnly: any;
  mobileNoPattern: any;
  mobileNoGlobalPattern: any;
  alphanumberPattern: any;
  companyNamePattern: any;
  blockSpecialCharacter: any;
  ValidationMessageKeys: any;
  noOfListingRecords: any;
  yearEquivalentMilliSecond: any;
  educationLevels: any;
  documents: any;
  numberRegex: any;
  urlPattern: any;
  upiIdPattern: any;
  razorPayKey: string;

  constructor() {
    this.EncryptKey = environment.encryptKey;
    this.SecretKeyForEncryptionWithIv = '';
    this.IV = '';
    this.APIBasePath = environment.APIBasePath;
    this.defaultLanguageId = 1;
    this.notificationTimeout = 7000;
    this.commonAPIErrorMessage = 'Server Error';
    this.APIRequestTimeout = 25000000;
    this.defaultLanguage = 'en';
    this.defaultLanguageObj = {
      id: 1,
      name: 'English',
      cultureCode: 'en',
      isRtl: false,
    };
    // tslint:disable-next-line: max-line-length
    this.numbersOnly =
      /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    this.blockSpecialCharacter = '^(?=.*[a-zA-Z])([a-zA-Z0-9_ ]+)$';
    this.mobileNoPattern = '^[0-9]{10}$';
    this.mobileNoGlobalPattern = '^[s()+-]*([0-9][s()+-]*){6,20}$';
    this.alphanumberPattern = '^[A-Za-z0-9 ]+$';
    this.ValidationMessageKeys = {
      required: '',
      invalidEmail: '',
      minlength: '',
    };
    this.numberRegex = /[^0-9]*/g;
    this.urlPattern =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    this.upiIdPattern = '^[a-zA-Z0-9.-_]{2,256}@[a-zA-Z]{2,64}$';
    this.razorPayKey = environment.RAZORPAY_KEY;
  }
}

export enum InputType {
  Password = 'Password',
  Text = 'Text',
}

export enum ToastTypes {
  Success = 0,
  Warning = 1,
  Error = 2,
}

export enum ToastMessages {
  'Check your internet connection' = 0,
  'Server error, please try again later' = 1,
  'Please log in again' = 2,
  'Unauthorized access' = 3,
  'Server not reachable, please try again later' = 4,
}
